var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"p-0"},[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-2",attrs:{"cols":"1"}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"11"}},[_c('PageHeader',{staticClass:"py-0",attrs:{"title":_vm.title}})],1)],1),_c('v-card',{staticClass:"p-0 mb-2",attrs:{"outlined":""}},[_c('v-container',{staticClass:"p-2"},[_c('v-row',[(!_vm.showFilters)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10","align":"left"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplyed}}):_vm._e()],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":_vm.showFilters ? '12' : '2',"align":"right","align-self":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();_vm.showFilters = !_vm.showFilters}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.loadPorcentajesVentas()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0 ",attrs:{"cols":"3"}},[_c('v-switch',{staticClass:"mt-2 pb-0 mb-1",attrs:{"hide-details":"auto","id":"switch-porcentaje-ventas-os","label":"Filtrar por vigencia"},on:{"change":function($event){return _vm.resetFecha()}},model:{value:(_vm.filtraVigencia),callback:function ($$v) {_vm.filtraVigencia=$$v},expression:"filtraVigencia"}})],1),_c('v-col',{staticClass:"pb-0 pt-1",attrs:{"cols":"2"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"dense":"","outlined":"","label":"Período de vigencia","id":"periodo-vigente","hint":"Formato AAAAMM","hide-details":"auto","type":"text","disabled":!_vm.filtraVigencia,"filled":!_vm.filtraVigencia,"rules":_vm.filtraVigencia
                    ? _vm.rules.required.concat(_vm.rules.periodoYyyyMm)
                    : []},model:{value:(_vm.periodo),callback:function ($$v) {_vm.periodo=$$v},expression:"periodo"}})],1),_c('v-col',{staticClass:"py-0 pt-2 text-right",attrs:{"cols":"7"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{staticClass:"py-0",attrs:{"color":"primary","elevation":"2","disabled":!_vm.isFormValid,"type":"submit","small":"","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.porcentajesVentaItems,"search":_vm.search,"loading":_vm.loading,"item-key":"osPorcVentaId","show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4","align":"end"}},[(_vm.canCreate)?_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openModalEditPorcentaje()}}},[_vm._v(" Nuevo ")]):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.data-table-expand",fn:function(ref){
                    var item = ref.item;
                    var isExpanded = ref.isExpanded;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(isExpanded ? "mdi-account-check" : "mdi-account"))])]}}],null,true)},[_c('span',[_vm._v("Usuarios")])])]}},{key:"expanded-item",fn:function(ref){
                    var headers = ref.headers;
                    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Fecha alta:")]),_vm._v(" "+_vm._s(item.fecAlta)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Usuario alta:")]),_vm._v(" "+_vm._s(item.usuAlta)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Fecha modificación:")]),_vm._v(" "+_vm._s(item.fecModi)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Usuario modificación:")]),_vm._v(" "+_vm._s(item.usuModi)+" ")])],1)],1)]}},{key:"item.osPorcVentaValor",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.helpers.floatToMoneyString(item.osPorcVentaValor, "", true)))])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModalEditPorcentaje(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar registro")])]):_vm._e(),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteOsPorcVenta(item.osPorcVentaId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar registro")])]):_vm._e()]}}],null,true)})],1),(_vm.openModalEdit)?_c('v-dialog',{attrs:{"max-width":"45rem","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.openModalEdit),callback:function ($$v) {_vm.openModalEdit=$$v},expression:"openModalEdit"}},[_c('EditPorcentajeVentaXOS',{attrs:{"osPorcVentaId":_vm.osPorcVentaId},on:{"closeModal":_vm.closeAndReload}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'35%',"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDeleteOsPorcVenta()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }