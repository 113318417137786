<template>
  <v-container class="p-0">
    <v-row>
      <v-col cols="1" class="pb-0 pt-2">
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
      </v-col>
      <v-col cols="11" class="py-0">
        <PageHeader class="py-0" :title="title" />
      </v-col>
    </v-row>
    <v-card class="p-0 mb-2" outlined>
      <v-container class="p-2">
        <v-row>
          <v-col
            cols="12"
            md="10"
            v-if="!showFilters"
            class="py-0"
            align="left"
            @click="showFilters = true"
          >
            <FiltersSelected :filters="filtersApplyed" v-if="!showFilters" />
          </v-col>
          <v-col
            cols="12"
            :md="showFilters ? '12' : '2'"
            align="right"
            align-self="center"
            class="py-0"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="primary--text"
                  @click.stop="showFilters = !showFilters"
                >
                  {{ closeFiltersIcon }}
                </v-icon>
              </template>
              <span>{{
                !showFilters ? "Mostrar filtros" : "Ocultar filtros"
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-form
            v-show="showFilters"
            v-model="isFormValid"
            ref="filters-form"
            id="filters-form"
            @submit.prevent="loadPorcentajesVentas()"
          >
            <v-row>
              <!--SWITCH Vigente al -->
              <v-col cols="3" class="py-0 ">
                <v-switch
                  class="mt-2 pb-0 mb-1"
                  hide-details="auto"
                  id="switch-porcentaje-ventas-os"
                  v-model="filtraVigencia"
                  @change="resetFecha()"
                  label="Filtrar por vigencia"
                ></v-switch>
              </v-col>
              <!-- Vigente al -->

              <v-col cols="2" class="pb-0 pt-1">
                <v-text-field
                  dense
                  outlined
                  v-model="periodo"
                  label="Período de vigencia"
                  id="periodo-vigente"
                  hint="Formato AAAAMM"
                  hide-details="auto"
                  type="text"
                  :disabled="!filtraVigencia"
                  :filled="!filtraVigencia"
                  v-mask="'######'"
                  :rules="
                    filtraVigencia
                      ? rules.required.concat(rules.periodoYyyyMm)
                      : []
                  "
                ></v-text-field>
              </v-col>
              <!-- BTON APLICAR -->
              <v-col cols="7" class="py-0 pt-2 text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      size="28"
                      @click="resetForm"
                    >
                      {{ clearFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>Limpiar filtros</span>
                </v-tooltip>
                <v-btn
                  color="primary"
                  elevation="2"
                  :disabled="!isFormValid"
                  class="py-0"
                  type="submit"
                  small
                  form="filters-form"
                >
                  Aplicar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-expand-transition>
      </v-container>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="porcentajesVentaItems"
        class="elevation-1"
        :search="search"
        :loading="loading"
        item-key="osPorcVentaId"
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4" align="end">
                <v-btn
                  color="primary"
                  class="to-right"
                  v-if="canCreate"
                  @click="openModalEditPorcentaje()"
                >
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="handleExpansion(item, isExpanded)"
                v-bind="attrs"
                size="20"
                v-on="on"
                >{{ isExpanded ? "mdi-account-check" : "mdi-account" }}</v-icon
              >
            </template>
            <span>Usuarios</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col cols="12" md="3">
                <strong>Fecha alta:</strong>
                {{ item.fecAlta }}
              </v-col>
              <v-col cols="12" md="3">
                <strong>Usuario alta:</strong>
                {{ item.usuAlta }}
              </v-col>
              <v-col cols="12" md="3">
                <strong>Fecha modificación:</strong>
                {{ item.fecModi }}
              </v-col>
              <v-col cols="12" md="3">
                <strong>Usuario modificación:</strong>
                {{ item.usuModi }}
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:[`item.osPorcVentaValor`]="{ item }">
          <span>{{
            helpers.floatToMoneyString(item.osPorcVentaValor, "", true)
          }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left v-if="canEdit">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModalEditPorcentaje(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar registro</span>
          </v-tooltip>
          <v-tooltip left v-if="canDelete">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deleteOsPorcVenta(item.osPorcVentaId)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar registro</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="45rem"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditPorcentajeVentaXOS
        :osPorcVentaId="osPorcVentaId"
        @closeModal="closeAndReload"
      ></EditPorcentajeVentaXOS>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDeleteOsPorcVenta()"
    />
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import EditPorcentajeVentaXOS from "@/components/modules/facturacionOS/EditPorcentajeVentaXOS.vue";
import helpers from "@/utils/helpers.js";

export default {
  name: "PorcentajesVentaxOS",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    FiltersSelected,
    EditPorcentajeVentaXOS
  },
  directives: { mask },

  data: vm => ({
    routeToGo: "FacturacionPrestaciones",
    title: "Porcentajes de Venta por O.S.",
    search: "",
    titleDelete: "¿Desea eliminar el registro?",
    helpers: helpers,
    rules: rules,
    searchIcon: enums.icons.SEARCH,
    editIcon: enums.icons.EDIT,
    optionCode: enums.webSiteOptions.PORCENTAJES_VENTA_OS,
    calendarIcon: enums.icons.CALENDAR,
    deleteIcon: enums.icons.DELETE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    isFormValid: true,
    loading: false,
    expanded: [],
    filtersApplyed: [],
    showFilters: true,
    porcentajesVentaItems: [],
    headers: [
      {
        text: "Convenio",
        align: "start",
        value: "osNom",
        sortable: false
      },
      {
        text: "Tipo de orden",
        align: "start",
        value: "toNom",
        sortable: false
      },
      {
        text: "Porcentaje",
        align: "end",
        value: "osPorcVentaValor",
        sortable: false
      },
      {
        text: "Período desde",
        align: "start",
        value: "vigenciaDesde",
        sortable: false
      },
      {
        text: "Período hasta",
        align: "start",
        value: "vigenciaHasta",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    showDeleteModal: false,
    filtraVigencia: true,
    periodo: null,
    allowedActions: null,
    canCreate: false,
    canEdit: false,
    canDelete: false,
    openModalEdit: false,
    osPorcVentaId: null
  }),
  created() {
    this.setFecha();
  },
  async mounted() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: true
    });

    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getPorcentajesVentaXOs: "facturacionOS/getPorcentajesVentaXOs",
      deletePorcentajeVentaXOs: "facturacionOS/deletePorcentajeVentaXOs",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVO_PORCENTAJES_VENTA_OS:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_PORCENTAJES_VENTA_OS:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_PORCENTAJES_VENTA_OS:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    setFecha() {
      const fecha = new Date();
      this.periodo =
        fecha.getFullYear().toString() +
        (fecha.getMonth() + 1).toString().padStart(2, "0");
    },
    resetFecha() {
      if (!this.filtraVigencia) {
        this.periodo = null;
      }
    },
    async loadPorcentajesVentas() {
      this.porcentajesVentaItems = [];
      this.loading = true;
      this.customizeFiltersApplied();
      try {
        const response = await this.getPorcentajesVentaXOs(this.periodo ?? "");
        this.porcentajesVentaItems = response;
        this.showFilters = false;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];
      if (this.periodo) {
        this.filtersApplyed.splice(0, 1, {
          key: "periodo",
          label: "Vigentes al periodo: ",
          model: this.periodo
        });
      }
      if (!this.filtraVigencia) {
        this.filtersApplyed.splice(0, 1, {
          key: "filtraVigencia",
          label: "Filtra por vigencia",
          model: this.filtraVigencia ? "Sí" : "No"
        });
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    deleteOsPorcVenta(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDeleteOsPorcVenta() {
      const response = await this.deletePorcentajeVentaXOs(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadPorcentajesVentas();
      }
    },
    openModalEditPorcentaje(item) {
      this.osPorcVentaId = item?.osPorcVentaId;
      this.openModalEdit = true;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadPorcentajesVentas();
    }
  }
};
</script>

<style></style>
