<template>
  <v-card>
    <v-container>
      <v-card-title class="py-0 primary--text">
        {{ title }}
      </v-card-title>
      <v-form
        v-model="isFormValid"
        ref="form-porcentaje-venta"
        form="form-porcentaje-venta"
        id="form-porcentaje-venta"
        @submit.prevent="saveOsPorcVenta()"
      >
        <v-card-text class="py-2">
          <v-row>
            <v-col cols="12" md="6" class="pt-0 pb-1 px-1">
              <v-autocomplete
                label="Convenio"
                v-model="convenioSelected"
                :items="convenios"
                item-text="value"
                item-value="id"
                id="convenio-porcentaje-ventas"
                ref="convenio-porcentaje-ventas"
                tabindex="1"
                hide-details="auto"
                clearable
                outlined
                dense
                :rules="rules.required"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-1 px-1">
              <v-autocomplete
                label="Tipo de orden"
                v-model="tipoOrdenSelected"
                :items="tiposOrden"
                item-text="value"
                item-value="id"
                id="tipo-orden-porcentaje-ventas"
                ref="tipo-orden-porcentaje-ventas"
                tabindex="2"
                hide-details="auto"
                clearable
                outlined
                dense
                :rules="tipoOrdenSelected === 0 ? [] : rules.required"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- Porcentaje de Venta -->

            <v-col cols="12" md="3" class="py-0 px-1">
              <currency-input
                v-model="porcentajeVenta"
                :append-icon="porcentajeIcon"
                id="valor-porcentaje-ventas"
                dense
                tabindex="3"
                :hideDetails="'auto'"
                :rules="[rules.decimalValidRanges(porcentajeVenta, 0, 100)]"
                :options="percentOptions"
                label="Porcentaje de venta"
                outlined
              >
              </currency-input>
            </v-col>

            <v-col cols="12" md="9" class="py-0 ">
              <v-row>
                <!-- periodo desde -->
                <v-col cols="6" class="py-0 px-1" md="6">
                  <v-text-field
                    dense
                    outlined
                    v-model="periodoDesde"
                    label="Período desde"
                    hint="Formato AAAAMM"
                    persistent-hint
                    hide-details="auto"
                    tabindex="4"
                    type="text"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    v-mask="'######'"
                    :rules="
                      rules.required.concat(
                        rules.periodoYyyyMm,
                        periodoHasta
                          ? [rules.validDateRange(periodoDesde, periodoHasta)]
                          : []
                      )
                    "
                  ></v-text-field>
                </v-col>
                <!-- periodo hasta -->
                <v-col cols="6" class="py-0 px-1" md="6">
                  <v-text-field
                    dense
                    outlined
                    v-model="periodoHasta"
                    label="Período hasta"
                    type="text"
                    hint="Formato AAAAMM"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    persistent-hint
                    v-mask="'######'"
                    hide-details="auto"
                    tabindex="5"
                    :rules="
                      rules.required.concat(
                        [rules.validDateRange(periodoDesde, periodoHasta)],
                        rules.periodoYyyyMm
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-0">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            id="btn-cancelar-porcentaje-ventas"
            tabindex="6"
            @click="closeModal"
          >
            Cancelar
          </v-btn>
          <v-btn
            type="submit"
            :disabled="!isFormValid"
            tabindex="7"
            id="btn-guardar-porcentaje-ventas"
            :loading="loadingSaveBtn"
            form="form-porcentaje-venta"
            color="primary"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "EditPorcentajeVentaXOS",
  directives: { mask },
  components: { CurrencyInput },
  props: {
    osPorcVentaId: {
      type: Number,
      require: false,
      default: null
    }
  },
  data() {
    return {
      title: "Nuevo porcentaje de venta por O.S",
      isFormValid: false,
      loadingSaveBtn: false,
      convenioSelected: null,
      tipoOrdenSelected: null,
      porcentajeVenta: null,
      periodoDesde: null,
      periodoHasta: null,
      convenios: [],
      tiposOrden: [],
      rules: rules,
      porcentajeIcon: enums.icons.PERCENT_OUTLINE,
      calendarIcon: enums.icons.CALENDAR,
      percentOptions: {
        locale: "es-ES",
        currency: "USD",
        currencyDisplay: "hidden",
        valueRange: {
          min: 0,
          max: 500
        },
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: false,
        accountingSign: false
      }
    };
  },
  async created() {
    await this.setSelects();
    if (this.osPorcVentaId != null) {
      await this.setPorcentajeVentaxOs();
    }
  },
  methods: {
    ...mapActions({
      getPorcentajeVentaXOsById: "facturacionOS/getPorcentajeVentaXOsById",
      savePorcentajeVentaXOs: "facturacionOS/savePorcentajeVentaXOs",
      getConvenios: "aportes/fetchConvenios",
      getTiposOrden: "taxonomy/getTiposOrden",
      getConveniosByVigencia: "afiliaciones/getConveniosByVigencia",

      setAlert: "user/setAlert"
    }),
    async setSelects() {
      // this.convenios = await this.getConvenios();
      this.convenios = await this.getConveniosByVigencia({
        fechaAlta: new Date().toISOString()
      });
      this.tiposOrden = await this.getTiposOrden();
      this.tiposOrden.unshift({
        id: -1,
        value: "Todos"
      });
      this.$nextTick(() => {
        this.$refs["convenio-porcentaje-ventas"].focus();
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },

    async setPorcentajeVentaxOs() {
      this.title = "Editar porcentaje de venta por O.S";
      const response = await this.getPorcentajeVentaXOsById(this.osPorcVentaId);
      const data = response;
      this.convenioSelected = data.osId;
      this.tipoOrdenSelected = data.toId;
      this.porcentajeVenta = data.osPorcVentaValor;
      this.periodoDesde = data.vigenciaDesde;
      this.periodoHasta = data.vigenciaHasta;
    },
    async saveOsPorcVenta() {
      this.loadingSaveBtn = true;
      const data = {
        osPorcVentaId: this.osPorcVentaId,
        OsId: this.convenioSelected,
        ToId: this.tipoOrdenSelected === -1 ? null : this.tipoOrdenSelected,
        OsPorcVentaValor: this.porcentajeVenta,
        VigenciaDesde: this.periodoDesde,
        VigenciaHasta: this.periodoHasta
      };
      try {
        const response = await this.savePorcentajeVentaXOs(data);
        if (response.status == 200) {
          this.setAlert({
            type: "success",
            message: "Porcentaje de venta guardado correctamente"
          });
          this.$emit("closeModal");
        }
      } catch (error) {
      } finally {
        this.loadingSaveBtn = false;
      }
    }
  }
};
</script>

<style></style>
